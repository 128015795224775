import { useEffect, useState } from 'react';
import { EventType } from '../../utils/types/EventType';
import { WhenzyService } from '../../utils/clients/WhenzyService';
import { useNavigate, useParams } from 'react-router-dom';

interface UseEventPageReturnValue {
  handleBackToCalendar: () => void;
  event: EventType | null;
  error: string;
  loading: boolean;
}

const whenzyService = new WhenzyService();

export const useEventPage = (): UseEventPageReturnValue => {
  const params = useParams<{ eventId: string }>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [event, setEvent] = useState<EventType | null>(null);
  const [error, setError] = useState('');

  const handleBackToCalendar = () => {
    navigate('/');
  };

  useEffect(() => {
    async function fetchEvent(eventId: string) {
      setLoading(true);
      try {
        const eventData = await whenzyService.getEvent(eventId);
        setEvent(eventData);
      } catch (err) {
        setError('Something went wrong trying to fetch the event, please try again later.');
      } finally {
        setLoading(false);
      }
    }

    if (params.eventId) {
      fetchEvent(params.eventId);
    }
  }, [params.eventId]);

  return {
    event,
    handleBackToCalendar,
    loading,
    error,
  };
};
