import { EventType } from '../../utils/types/EventType';

export const doesStringMatchQuery = (query: string, target: string): boolean => {
  const queryWords = query.toLowerCase().split(/\s+/).filter(Boolean);

  return queryWords.every((word: string) => target.toLowerCase().includes(word));
};

export const doesQueryMatch = (query: string, event: EventType): boolean => {
  return doesStringMatchQuery(query, event.title);
};
