import { FacebookEmbed } from 'react-social-media-embed';
import InstagramEmbedWrapper from './InstagramEmbedWrapper';
import styled from 'styled-components';

enum SocialMediaDomain {
  Instagram = 'www.instagram.com',
  Facebook = 'www.facebook.com',
}

interface SocialMediaEmbedProps {
  link: string;
}

const parseSocialMediaLink = (link: string): React.ReactElement | null => {
  let url: URL;
  try {
    url = new URL(link);
  } catch (e) {
    // Link provided is not a valid URL
    return null;
  }

  switch (url.hostname) {
    case SocialMediaDomain.Instagram: {
      return <InstagramEmbedWrapper link={url.toString()} />;
    }
    case SocialMediaDomain.Facebook: {
      return <FacebookEmbed width={'100%'} url={url.toString()} />;
    }
    default: {
      return null;
    }
  }
};

const SocialMediaEmbed = ({ link }: SocialMediaEmbedProps): React.ReactElement | null => {
  const component = parseSocialMediaLink(link);

  if (!component) {
    return null;
  }

  return <SocialMediaWrapper>{component}</SocialMediaWrapper>;
};

const SocialMediaWrapper = styled.div`
  background-color: white;
`;

export default SocialMediaEmbed;
