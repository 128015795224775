import React, { useEffect, useState } from 'react';
import { days } from '../../utils/consts/days';
import { months } from '../../utils/consts/months';
import styles from './styles.module.css';
import { useStore } from '../../utils/store/useStore';
import { getCurrentWeather } from '../../utils/store/store';
import { useModal } from '../../utils/modal/useModal';
import { WeatherInfo } from '../../utils/api/getData';
import WeatherDetails from '../WeatherDetails';
import { getWeatherIconUrl } from '../../utils/funcs/weather';

export type DateWeatherPanelProps = {
  weather: WeatherInfo | null;
  isHeaderVisible: boolean;
};

const DateWeatherPanel: React.FC<DateWeatherPanelProps> = ({ weather, isHeaderVisible }) => {
  const [dateData, setDateData] = useState<{
    day: string;
    month: string;
    date: number;
  }>();

  const { state, dispatch } = useStore();
  const modalManager = useModal();
  const headerClass = isHeaderVisible ? styles.header_visible : styles.header_hidden;

  useEffect(() => {
    const date = new Date();
    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dateOfMonth = date.getDate();
    setDateData({
      day,
      month,
      date: dateOfMonth,
    });
    getCurrentWeather(dispatch)
      .then((data) => {})
      .catch((error) => {
        console.error('Error fetching weather data:', error);
      });
  }, [dispatch]);

  const openWeatherDetailsModal = () => {
    modalManager.open(<WeatherDetails weather={weather} getWeatherIconUrl={getWeatherIconUrl} />);
  };

  return (
    <div className={`${styles.dateWeatherPanel} ${headerClass}`}>
      {dateData?.day}, {dateData?.month} {dateData?.date} |
      {weather && (
        <span className={styles.weatherWrapper} onClick={openWeatherDetailsModal}>
          {state.currentWeather.temperature !== undefined
            ? Math.round(state.currentWeather.temperature) + '°F'
            : 'N/A'}
          {weather?.current.icon_name !== undefined ? (
            <img
              src={getWeatherIconUrl(weather.current.icon_name)}
              alt="Weather Icon"
              className={styles.weatherIcon}
            />
          ) : (
            'N/A'
          )}
        </span>
      )}
    </div>
  );
};

export default DateWeatherPanel;
