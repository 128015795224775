import { InstagramEmbed } from 'react-social-media-embed';

interface InstagramEmbedWrapperProps {
  link: string;
}

const InstagramEmbedWrapper = ({ link }: InstagramEmbedWrapperProps) => {
  return <InstagramEmbed captioned url={link} />;
};

export default InstagramEmbedWrapper;
