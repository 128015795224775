import React from 'react';
import styled from 'styled-components';
import SearchInput from './SearchInput';
// import Messages from './Messages'; TODO when realise chat support
import { useStore } from '../../utils/store/useStore';
import { search } from '../../utils/store/store';
import { mediaLargerThan } from '../../common/styles';

export type ChatProps = {};

const Chat: React.FC<ChatProps> = () => {
  const { state, dispatch } = useStore();

  const handleMultiWordSearch = async (message: string) => {
    await search(dispatch, state, message);
  };

  return (
    <ChatWrapper>
      <ChatContainer>
        {/* TODO when realise chat support <Messages messages={state.messages} /> */}
        <SearchInput onSearch={handleMultiWordSearch} />
      </ChatContainer>
    </ChatWrapper>
  );
};

const ChatWrapper = styled.div`
  ${mediaLargerThan.tablet} {
    display: flex;
    align-items: end;
  }
`;

const ChatContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 30px 30px 0 0;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  background-color: var(--dark-color);
  box-shadow: 3px -4px 4px rgba(33, 44, 65, 0.6);

  ${mediaLargerThan.tablet} {
    position: relative;
    border-radius: 10px;
    padding: 1rem;
    width: 80%;
  }
`;

export default Chat;
