import React, { useState, useEffect } from 'react';
import { Weather, WeatherInfo, LocationInfo, getLocation } from '../../utils/api/getData';
import { useModal } from '../../utils/modal/useModal';
import close from '../../assets/images/close-button-png-30238.png';
import calendar from '../../assets/images/calendar.svg';
import styles from './styles.module.css';
import { convertTimestampToTime } from '../../utils/funcs/timeUtils';

export type WeatherDetailsProps = {
  weather: WeatherInfo | null;
  getWeatherIconUrl: (iconcode: string) => string;
};

const LINK_TO_OPENWEATHER = 'https://openweathermap.org/';

const WeatherDetails: React.FC<WeatherDetailsProps> = ({ weather, getWeatherIconUrl }) => {
  const modalManager = useModal();
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const currentHour = new Date(currentTimestamp * 1000).getHours();
  const [locationName, setLocationName] = useState('');

  const { daily }: { daily?: Weather[] } = weather || {};

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        // changing to hard code Moorestown, NJ until more organizations are added
        //const location: LocationInfo = await getLocation();
        // console.log(location);
        setLocationName('Moorestown, NJ'); // Assuming locationName is the property you want to set
      } catch (error) {
        console.error(error);
      }
    };

    fetchLocation();
  }, []);

  return (
    <div className={styles.weatherContainer}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>
          CURRENT WEATHER FOR <br />
          <p className={styles.headerLocation}> {locationName}</p>
        </h2>
        <img src={close} className={styles.close} onClick={() => modalManager.close()} />
      </div>
      <div className={styles.currentWeatherWrapper}>
        <div className={styles.currentWeather}>
          <p className={styles.currentTemperature}>
            {weather?.current.temperature !== undefined
              ? Math.round(weather.current.temperature) + '°F'
              : 'N/A'}
          </p>
        </div>
        <div className={styles.currentWeatherDescription}>
          {weather?.current.icon_name !== undefined ? (
            <img
              src={getWeatherIconUrl(weather.current.icon_name)}
              alt="Weather Icon"
              className={styles.weatherIcon}
            />
          ) : (
            'N/A'
          )}

          {weather?.current.description}
        </div>
      </div>
      <div className={styles.hourlyWeather}>
        <div className={styles.hourlyList}>
          {weather?.hourly.slice(0, 6).map((hour) => (
            <div key={hour.time} className={styles.hourlyItem}>
              {new Date(hour.time! * 1000).getHours() === currentHour &&
              new Date(hour.time! * 1000).getMinutes() >= 0
                ? 'Now'
                : convertTimestampToTime(hour.time!)}
              <p className={styles.hourlyItem}>
                {hour.icon_name !== undefined ? (
                  <img
                    src={getWeatherIconUrl(hour.icon_name)}
                    alt="Weather Icon"
                    className={styles.weatherIcon}
                  />
                ) : (
                  'N/A'
                )}
              </p>
              <p className={styles.hourlyItem}>
                {hour.temperature !== undefined ? Math.round(hour.temperature) + '°F' : 'N/A'}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.dailyWeather}>
        <h3>
          <img src={calendar} alt="calendar icon" className={styles.calendar_10day_icon} />
          10-day Forecast
        </h3>
        <div className={styles.dailyList}>
          {daily?.map((day: any) => (
            <div key={day.time} className={styles.dailyItem}>
              {/* <div className={styles.dayInfo}> */}
              <div className={styles.dayName}>
                {convertTimestampToTime(day.time, true).replace('12:00', '')}
              </div>
              <div className={styles.temperatureContainer}>
                <span className={styles.temperature}>
                  {typeof day.temperature === 'object' && 'min' in day.temperature
                    ? `${Math.round(day.temperature.min)}°F | `
                    : `${Math.round(day.temperature)}°F | `}
                </span>
                <span className={styles.temperature}>
                  {typeof day.temperature === 'object' && 'max' in day.temperature
                    ? `\u00A0${Math.round(day.temperature.max)}°F`
                    : `\u00A0${Math.round(day.temperature)}°F`}
                </span>
                {/* </div> */}
              </div>
              {day.icon_name !== undefined ? (
                <img
                  src={getWeatherIconUrl(day.icon_name)}
                  alt="Weather Icon"
                  className={styles.weatherIcon}
                />
              ) : (
                'N/A'
              )}
            </div>
          ))}
        </div>
      </div>
      <a
        className={styles.linkToOpenWeather}
        href={LINK_TO_OPENWEATHER}
        target="_blank"
        rel="noreferrer"
      />
    </div>
  );
};

export default WeatherDetails;
