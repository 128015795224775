import React from 'react';
import pageStyles from '../styles.module.css';
import styles from './styles.module.css';

export type AboutPageProps = {};

const AboutPage: React.FC<AboutPageProps> = () => {
  return (
    <div className={pageStyles.page}>
      <h3 className={pageStyles.pageTitle}>About us</h3>
      <div className={pageStyles.pageBody}>
        <div className={styles.infoBlock}>
          <h6 className={styles.title}>What is Whenzy?</h6>
          <span className={styles.description}>
          Whenzy is a long list of whens. We compile local happenings - events, meetings, activities - all across Moorestown. We&apos;re local technology fans, striving to build tools that are helpful. Let us know how we&apos;re doing?
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
