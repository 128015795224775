import React, { useEffect, useRef, useDeferredValue } from 'react';
import styles from './styles.module.css';
import mainStyles from '../../pages/calendar/styles.module.css';
import CalendarHeader from './CalendarHeader';
import CalendarBody from './CalendarBody';
import { useStore } from '../../utils/store/useStore';
import { getEventsByCategory, getOrganizations } from '../../utils/store/store';

export type CalendarProps = {
  isRunTopScroll?: boolean;
  onScroll: (scrollTop: number) => void;
};

const Calendar: React.FC<CalendarProps> = ({ isRunTopScroll, onScroll }) => {
  const { state, dispatch } = useStore();
  const { selectedOrgCategories } = state;
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const events = useDeferredValue(state.events);

  useEffect(() => {
    getOrganizations(dispatch).catch();
    getEventsByCategory(dispatch, selectedOrgCategories).catch();
  }, [selectedOrgCategories, dispatch]);

  return (
    <div className={`${mainStyles.block} ${styles.calendar}`} ref={calendarContainerRef}>
      <CalendarHeader />
      <CalendarBody events={events} isRunTopScroll={isRunTopScroll} onScrollChange={onScroll} />
    </div>
  );
};

export default Calendar;
