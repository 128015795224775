import axios from 'axios';
import { EventType } from '../types/EventType';

interface IWhenzyService {
  getEvent: (eventId: string) => Promise<EventType>;
}

export class WhenzyService implements IWhenzyService {
  private readonly baseUrl = `${process.env.REACT_APP_DATA_URL}`;

  async getEvent(eventId: string): Promise<EventType> {
    const url = new URL(`/calendar/${eventId}`, this.baseUrl);
    const response = await axios.get<EventType>(url.toString());
    return response.data;
  }
}
