import * as XLSX from 'xlsx';

export const getData = async () => {
  const res =
    process.env.REACT_APP_IS_LOCAL_DATA === 'false'
      ? await fetch(process.env.REACT_APP_DATA_URL + 'calendar' ?? '')
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            return data;
          })
          .catch((error) => {
            console.error('There was a problem fetching the file:', error);
          })
      : await fetch(process.env.PUBLIC_URL + '/data.xlsx')
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.arrayBuffer();
          })
          .then((data) => {
            const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            return jsonData.filter((value) => (value as unknown as string).length > 0);
          })
          .catch((error) => {
            console.error('There was a problem fetching the file:', error);
          });

  return res;
};

export const getOrganizationData = async () => {
  const res = await fetch(process.env.REACT_APP_DATA_URL + 'v1/organizations' ?? '')
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('There was a problem fetching the data:', error);
    });
  return res;
};

export interface Weather {
  // Note: Time is a Unix timestamp and will need to be translated
  // location: string;
  time?: number;
  temperature?: number;
  icon_name?: string;
  description: string;
}

export interface WeatherInfo {
  current: Weather;
  hourly: Weather[];
  daily: Weather[];
}

interface OpenWeatherInfo {
  dt: number;
  sunrise: number;
  sunset: number;
  temp: number;
  feels_like: number;
  pressure: number;
  humidity: number;
  dew_point: number;
  uvi: number;
  wind_speed: number;
  wind_deg: number;
  weather: OpenWeatherDescription[];
  pop?: number;
}
interface OpenWeatherDescription {
  id: number;
  main: string;
  description: string;
  icon: string;
}
interface OpenWeatherAlerts {
  sender_name: string;
  event: string;
  start: number;
  end: number;
  description: string;
  tags: string[];
}
interface OpenWeatherResponse {
  lat: number;
  lon: number;
  timezone: string;
  timezone_offset: number;
  current: OpenWeatherInfo;
  hourly: OpenWeatherInfo[];
  daily: OpenWeatherInfo[];
  alerts: OpenWeatherAlerts[];
}

export type LocationInfo = {
  latitude: number;
  longitude: number;
  locationName: string;
};

export const getWeather = async (location: LocationInfo, units: string): Promise<WeatherInfo> => {
  const { latitude, longitude } = location;
  const openWeatherURL = `https://api.openweathermap.org/data/3.0/onecall?lat=${latitude}&lon=${longitude}&units=${units}&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}`;

  const response = await fetch(openWeatherURL)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok!');
      }
      return response.json() as Promise<OpenWeatherResponse>;
    })
    .then((data) => {
      const weatherInfo: WeatherInfo = {
        current: {
          time: data.current.dt,
          temperature: data.current.temp,
          icon_name: data.current.weather[0].icon,
          description: data.current.weather[0].description,
        },
        hourly: data.hourly.map((weatherInfo) => ({
          time: weatherInfo.dt,
          temperature: Array.isArray(weatherInfo.temp) ? weatherInfo.temp[0] : weatherInfo.temp,
          icon_name: weatherInfo.weather[0].icon,
          description: weatherInfo.weather[0].description,
        })),
        daily: data.daily.map((weatherInfo) => ({
          time: weatherInfo.dt,
          temperature: weatherInfo.temp,
          icon_name: weatherInfo.weather[0].icon,
          description: weatherInfo.weather[0].description,
        })),
      };
      return weatherInfo;
    })
    .catch((error) => {
      console.error(`There was an issue with fetching data: ${error}`);
    });
  return response!;
};

export async function getLocation(): Promise<LocationInfo> {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          try {
            // Get the location name using OpenWeatherMap Geocoding API
            const locationName = await getCityNameFromCoordinates(latitude, longitude);
            resolve({
              latitude,
              longitude,
              locationName,
            });
          } catch (error: any) {
            reject('Error getting location: ' + error.message);
          }
        },
        (error) => {
          reject('Error getting location: ' + error.message);
        },
      );
    } else {
      reject('Geolocation is not available in this browser.');
    }
  });
}

async function getCityNameFromCoordinates(latitude: number, longitude: number): Promise<string> {
  const url = `http://api.openweathermap.org/geo/1.0/reverse?lat=${latitude}&lon=${longitude}&limit=1&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok!');
    }

    const data = await response.json();

    if (data && data.length > 0 && data[0].name) {
      return data[0].name;
    } else {
      throw new Error('City name not found in the API response');
    }
  } catch (error) {
    console.error('Error while getting city name:', error);
    return 'City not found';
  }
}
