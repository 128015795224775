const Cost = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18" fill="none">
    <circle cx="8.5" cy="9" r="7" fill="#EFF7FF" />
    <path
      d="M6.5 10.5139C6.5 11.2144 7.04168 11.7777 7.70612 11.7777H9.06388C9.64166 11.7777 10.1111 11.2866 10.1111 10.6727C10.1111 10.0155 9.82223 9.77719 9.39612 9.62552L7.22222 8.86718C6.79611 8.71552 6.50723 8.48441 6.50723 7.81996C6.50723 7.2133 6.97666 6.71497 7.55444 6.71497H8.91222C9.57666 6.71497 10.1183 7.2783 10.1183 7.97885"
      stroke="#212C41"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.30554 6V12.5" stroke="#212C41" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Cost;
