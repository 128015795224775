import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import briefcase from '../../../assets/images/briefcase.svg';
import clock from '../../../assets/images/clock.svg';
import location from '../../../assets/images/location.svg';
import { CalendarDate } from '../CalendarDate';
import { EventType } from '../../../utils/types/EventType';
import { groupEventsByDate } from '../../../utils/funcs/groupEventsByDate';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { mediaLargerThan } from '../../../common/styles';

export type CalendarBodyProps = {
  events: EventType[];
  isRunTopScroll?: boolean;
  onScrollChange: (scrollTop: number) => void;
};

const CalendarBody: React.FC<CalendarBodyProps> = ({ events, isRunTopScroll, onScrollChange }) => {
  const eventsRef = useRef<HTMLDivElement>(null);
  const lastStickyRef = useRef<any>(null);
  const navigate = useNavigate();
  const [headerRefs, setHeaderRefs] = useState<React.RefObject<HTMLDivElement>[]>([]);
  const [currentScrollPosition, setCurrentScrollPosition] = useState<number | null>(null);

  const groupedEvents = groupEventsByDate(events);

  useEffect(() => {
    const previousScrollPosition = sessionStorage.getItem('calendarScrollPosition');
    if (eventsRef.current && previousScrollPosition) {
      eventsRef.current.scrollTo({ top: parseInt(previousScrollPosition), behavior: 'smooth' });
    }
  }, [events]);

  useEffect(() => {
    setHeaderRefs(events.map(() => React.createRef<HTMLDivElement>()));
  }, [events]);

  const handleScroll = useCallback(() => {
    if (eventsRef.current) {
      const scrollTop = eventsRef.current.scrollTop;
      onScrollChange(scrollTop);
      const container = document.querySelector('eventsWrapper');
      if (container) {
        const isScrollable = container.scrollHeight > container.clientHeight;
        console.log('Is container scrollable?', isScrollable);
      }
    }
  }, [onScrollChange]);

  const handleStickyDate = useCallback(() => {
    const parent = document.getElementById('calendar-parent')?.getBoundingClientRect();

    headerRefs.forEach((ref) => {
      const header = ref.current;

      if (header && parent) {
        const rect = header.getBoundingClientRect();
        const relativeTop = rect.bottom - parent.top;
        if (relativeTop < 100) {
          header.classList.add(styles.unsticky);
        } else {
          header.classList.remove(styles.unsticky);
        }
      }
    });
  }, [headerRefs]);

  const handleTrackScroll = () => {
    setCurrentScrollPosition(eventsRef.current?.scrollTop || 0);
  };

  const handleEventClick = (eventId: string) => {
    if (currentScrollPosition !== null) {
      sessionStorage.setItem('calendarScrollPosition', currentScrollPosition.toString());
    }
    navigate(`/event/${eventId}`);
  };

  useEffect(() => {
    // scrollToTop();
    handleScroll();
    lastStickyRef.current = null;
    const container = eventsRef.current;
    if (container) {
      container.addEventListener('scroll', handleStickyDate);
      container.addEventListener('scroll', handleTrackScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleStickyDate);
        container.removeEventListener('scroll', handleTrackScroll);
      }
    };
  }, [isRunTopScroll, headerRefs, handleScroll, handleStickyDate]);

  return (
    <div
      id="calendar-parent"
      className={styles.eventsWrapper}
      ref={eventsRef}
      onScroll={handleScroll}
    >
      {Object.entries(groupedEvents).map(([date, eventsForDate], index) => (
        <DateGroup key={date} ref={headerRefs[index]}>
          <div className={styles.dateHeader}>
            <CalendarDate
              date={eventsForDate[0].date}
              isFirst={index === 0}
              isCurrentDay={index > 0 ? date === events[index - 1].date : false}
            />
          </div>
          {eventsForDate.map((event, eventIndex) => {
            const orgName = event.organization === 'Featured' ? event.category : event.organization;

            return (
              <EventContainer key={eventIndex} onClick={() => handleEventClick(event.event_id)}>
                <div className={styles.eventData}>
                  <EventTitle>{event.title}</EventTitle>
                  {orgName && (
                    <div className={`${styles.eventOrg} ${styles.eventExtra}`}>
                      <img src={briefcase} className={styles.eventIcon} />
                      {orgName}
                    </div>
                  )}
                  <div className={styles.eventInfo}>
                    {event.time && (
                      <div className={`${styles.eventTime} ${styles.eventExtra}`}>
                        <img src={clock} className={styles.eventIcon} />
                        {event.time}
                      </div>
                    )}
                    {event.address && (
                      <div className={`${styles.eventLocation} ${styles.eventExtra}`}>
                        <img src={location} className={styles.eventIcon} />
                        {event.address?.split(',', 2).join(',')}
                      </div>
                    )}
                  </div>
                </div>
              </EventContainer>
            );
          })}
        </DateGroup>
      ))}
    </div>
  );
};

const EventContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  padding-left: 80px;
  word-break: normal;
  text-wrap: wrap;
  min-height: 100px;

  ${mediaLargerThan.mobile} {
    width: 90%;
  }
`;

const EventTitle = styled.h6`
  font-size: 14px;
  word-break: break-word;
  line-height: 17px;
  font-weight: 700;
  margin-bottom: 4px;
  white-space: normal;
  font-family: 'Verdana-Bold', sans-serif;

  ${mediaLargerThan.mobile} {
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 6px;
  }

  ${mediaLargerThan.tablet} {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 23px;
  }
`;

const DateGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 20px;

  &::after {
    position: absolute;
    left: 74px;
    top: 0;
    height: 100%;
    bottom: 0;
    width: 2px;
    background-color: var(--light-color);
    content: '';
  }

  ${mediaLargerThan.mobile} {
    padding-left: 30px;
    &::after {
      left: 84px;
    }
  }
`;

export default observer(CalendarBody);
