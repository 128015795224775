import {
  getData,
  getOrganizationData,
  Weather,
  getWeather,
  WeatherInfo,
  LocationInfo,
} from '../api/getData';
import { doesStringMatchQuery } from '../search/search';
// import { MessageAuthor } from '../enums/MessageAuthor';
import { EventType } from '../types/EventType';
import { MessageType } from '../types/MessageType';
import { OrganizationType } from '../types/OrganizationType';

export interface State {
  events: EventType[];
  cachedEvents: EventType[];
  organizations: OrganizationType[];
  subOrganizations: string[];
  actualOrganizations: OrganizationType[];
  currentWeather: Weather;
  hourlyWeather: Weather[];
  dailyWeather: Weather[];
  selectedOrgCategories: OrganizationType[];
  // TODO when realise chat support messages: MessageType[];
}

export enum Action {
  setEvents = 'setEvents',
  setCachedEvents = 'setCachedEvents',
  setMessages = 'setMessages',
  setOrganizations = 'setOrganizations',
  setActualOrganizations = 'setActualOrganizations',
  setCurrentWeather = 'setCurrentWeather',
  setHourlyWeather = 'setHourlyWeather',
  setDailyWeather = 'setDailyWeather',
  setSelectedOrgCategories = 'setSelectedOrgCategories',
}

export type ActionType =
  | { type: Action.setEvents; data: EventType[] }
  | { type: Action.setCachedEvents; data: EventType[] }
  | { type: Action.setMessages; data: MessageType[] }
  | {
      type: Action.setOrganizations;
      data: OrganizationType[];
    }
  | {
      type: Action.setActualOrganizations;
      data: OrganizationType[];
    }
  | {
      type: Action.setCurrentWeather;
      data: Weather;
    }
  | {
      type: Action.setHourlyWeather;
      data: Weather[];
    }
  | {
      type: Action.setDailyWeather;
      data: Weather[];
    }
  | {
      type: Action.setSelectedOrgCategories;
      data: OrganizationType[];
    };

export const initialState: State = {
  events: [],
  cachedEvents: [],
  organizations: [],
  subOrganizations: [],
  actualOrganizations: [],
  currentWeather: {
    time: 0,
    temperature: 0,
    icon_name: '',
    description: '',
  },
  hourlyWeather: [],
  dailyWeather: [],
  selectedOrgCategories: [
    {
      name: 'Featured',
    },
  ],
  // TODO when realise chat support messages: [
  //   {
  //     author: MessageAuthor.Whensy,
  //     message: "I'm glad to answer your questions.",
  //   },
  // ],
};

export const reducer = (state: State, action: ActionType): State => {
  switch (action.type) {
    case Action.setEvents:
      return { ...state, events: [...action.data] };
    case Action.setCachedEvents:
      return { ...state, cachedEvents: [...action.data] };
    case Action.setActualOrganizations:
      return {
        ...state,
        actualOrganizations: Array.from(action.data),
      };
    case Action.setOrganizations:
      return {
        ...state,
        organizations: Array.from(action.data),
      };
    case Action.setCurrentWeather:
      return {
        ...state,
        currentWeather: action.data,
      };
    case Action.setHourlyWeather:
      return {
        ...state,
        hourlyWeather: Array.from(action.data),
      };
    case Action.setDailyWeather:
      return {
        ...state,
        hourlyWeather: Array.from(action.data),
      };
    case Action.setSelectedOrgCategories:
      return {
        ...state,
        selectedOrgCategories: [...action.data],
      };
    // TODO when realise chat support case Action.setMessages:
    //   return { ...state, messages: [...state.messages, ...action.data] };
    default:
      // add helpful message for next time this happens
      console.log(
        'State Remained Unchanged. If trying to change state make sure action is defined.',
      );
      return state;
  }
};

export const search = async (
  dispatch: React.Dispatch<ActionType>,
  state: State,
  message: string,
) => {
  const response = state.cachedEvents ? state.cachedEvents : await getData();

  if (response) {
    let events = state.cachedEvents;
    if (events) {
      events = formatData(events).events;
    }

    const doesEventMatch = (event: any, query: string) => {
      for (const key in event) {
        if (doesStringMatchQuery(query, event[key]?.toString() || '')) {
          return true;
        }
      }
      return false;
    };

    const searchEvents = events.filter((event) => doesEventMatch(event, message));

    const organizations: OrganizationType[] = [];

    searchEvents.map((item) => {
      if (item?.organization) {
        const existed = organizations.find((value) => value.name === item.organization);
        if (existed) {
          if (item?.subOrganization) {
            const newSubs = existed.subs
              ? new Set([...existed.subs, item.subOrganization])
              : [item.subOrganization];

            organizations[organizations.indexOf(existed)] = {
              name: existed.name,
              subs: Array.from(newSubs),
            };
          }
        } else {
          if (item?.subOrganization) {
            organizations.push({
              name: item.organization,
              subs: [item.subOrganization],
            });
          } else {
            organizations.push({
              name: item.organization,
            });
          }
        }
      }
    });

    if (message === '') {
      await getEvents(dispatch, state);
    } else {
      dispatch({ type: Action.setEvents, data: searchEvents });
      dispatch({ type: Action.setActualOrganizations, data: organizations });
    }
  }

  // TODO when realise chat support
  // const searchMessage = searchEvents.length
  //   ? searchEvents.reduce((str, item, index) => {
  //       return (
  //         str +
  //         `${index + 1}. ${item.time} ${item.title} at ${item.date} ${
  //           index + 1 < searchEvents.length ? ', ' : ''
  //         }\n`
  //       );
  //     }, 'There are events:\n')
  //   : 'There are no events(';

  // dispatch({
  //   type: Action.setMessages,
  //   data: [
  //     {
  //       author: MessageAuthor.You,
  //       message,
  //     },
  //     {
  //       author: MessageAuthor.Whensy,
  //       message: searchMessage,
  //     },
  //   ],
  // });
};

export const getEvents = async (dispatch: React.Dispatch<ActionType>, state: State) => {
  if (state.cachedEvents) {
    dispatch({ type: Action.setEvents, data: state.cachedEvents });
    dispatch({ type: Action.setActualOrganizations, data: state.organizations });
    return;
  }
  const response = await getData();

  if (response) {
    const { events } = formatData(response);
    dispatch({ type: Action.setEvents, data: events });
  }
};
export const getOrganizations = async (dispatch: React.Dispatch<ActionType>) => {
  const response = await getOrganizationData();

  if (response) {
    const { organizations } = formatOrganizations(response);
    dispatch({ type: Action.setOrganizations, data: organizations });
  }
};
export const getEventsByCategory = async (
  dispatch: React.Dispatch<ActionType>,
  orgs: OrganizationType[],
) => {
  const response = await getData();

  if (response) {
    const { events } = formatData(response);
    dispatch({ type: Action.setCachedEvents, data: events });

    const filteredEvents = events.filter((event) => {
      return orgs.find((org) =>
        event.category && org.name !== 'Featured'
          ? event.organization === org.name && org.subs?.includes(event.category)
          : event.organization === org.name,
      );
    });

    dispatch({ type: Action.setEvents, data: orgs.length ? filteredEvents : events });
    dispatch({ type: Action.setActualOrganizations, data: orgs });
  }
};

const formatOrganizations = (fileData: any[]): { organizations: OrganizationType[] } => {
  const organizations: OrganizationType[] = [];

  fileData.forEach((item) => {
    if (item?.organization) {
      const exists = organizations.find((value) => value.name === item.organization);
      if (exists) {
        if (item?.organization === 'Featured') {
          return;
        }
        if (item?.category) {
          const newSubs = exists.subs
            ? Array.from(new Set([...exists.subs, item.category])).sort()
            : [item.category];

          organizations[organizations.indexOf(exists)] = {
            name: exists.name,
            subs: newSubs,
          };
        }
      } else {
        if (item?.category && item.organization !== 'Featured') {
          organizations.push({
            name: item.organization,
            subs: [item.category],
          });
        } else {
          organizations.push({
            name: item.organization,
          });
        }
      }
    }
  });
  return { organizations: organizations };
};

const formatData = (
  fileData: any[],
): {
  events: EventType[];
} => {
  const events: EventType[] = [];

  if (process.env.REACT_APP_IS_LOCAL_DATA === 'false') {
    fileData.forEach((item) => {
      events.push(item as EventType);
    });
  } else {
    if (fileData.length) {
      const keys = fileData[0];
      const dateColumnIndex = keys.indexOf('Date');

      for (let i = 1; i < fileData.length; i++) {
        if (new Date(fileData[i][dateColumnIndex]) > new Date()) {
          let item: any = {};

          for (const [index, key] of keys.entries()) {
            if (key === 'Sub-Organization') {
              item = { ...item, subOrganization: fileData[i][index] };
            } else {
              item = { ...item, [key.toLowerCase()]: fileData[i][index] };
            }
          }
          events.push(item as EventType);
        }
      }
    }
  }
  return { events };
};

// export const getCurrentWeather = async (dispatch: React.Dispatch<ActionType>) => {
//   const weatherInfo: void | WeatherInfo = await getWeather(39.9689, -74.9489, 'imperial');
//   dispatch({ type: Action.setCurrentWeather, data: weatherInfo['current'] });
// };
export const getCurrentWeather = async (
  dispatch: React.Dispatch<ActionType>,
): Promise<WeatherInfo> => {
  try {
    // keeping for when new organizations are created, for now location will be Moorestown, NJ
    //const location = await getLocation();
    const location: LocationInfo = {
      latitude: 39.97,
      longitude: -74.95,
      locationName: 'Moorestown, NJ',
    };
    const weatherInfo: WeatherInfo = await getWeather(location, 'imperial');
    dispatch({ type: Action.setCurrentWeather, data: weatherInfo['current'] });
    return weatherInfo;
  } catch (error) {
    console.error('Error fetching weather data:', error);
    throw new Error('Failed to fetch weather data'); // Replace with your default value function
  }
};

export const getHourlyDailyWeather = async (dispatch: React.Dispatch<ActionType>) => {
  try {
    // keeping for when new organizations are created, for now location will be Moorestown, NJ
    //const location = await getLocation();
    const location: LocationInfo = {
      latitude: 39.97,
      longitude: -74.95,
      locationName: 'Moorestown, NJ',
    };
    const weatherInfo = await getWeather(location, 'imperial');
    dispatch({ type: Action.setHourlyWeather, data: weatherInfo['hourly'] });
    dispatch({ type: Action.setDailyWeather, data: weatherInfo['daily'] });
  } catch (error) {
    console.error('Error fetching weather data:', error);
    // Handle the error as needed (e.g., show an error message to the user)
  }
};
