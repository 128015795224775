import { useEffect, useState } from 'react';

export const useScrollVisibility = (onVisibilityChange: (isVisible: boolean) => void) => {
  const [isHeaderVisible, setHeaderVisible] = useState<boolean>(true);
  const [prevScrollPos, setPrevScrollPos] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const scrollingDown = currentScrollPos > prevScrollPos;
      if (scrollingDown && isHeaderVisible) {
        setHeaderVisible(false);
      } else if (!scrollingDown && !isHeaderVisible) {
        setHeaderVisible(true);
      }
      setPrevScrollPos(currentScrollPos);
      onVisibilityChange(isHeaderVisible);
    };

    const handleWheel = (event: any) => {
      const delta = event.deltaY;
      if (delta > 0 && isHeaderVisible) {
        setHeaderVisible(false);
      } else if (delta < 0 && !isHeaderVisible) {
        setHeaderVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [isHeaderVisible, prevScrollPos]);

  return isHeaderVisible;
};
