import { days } from '../consts/days';

export const convertTimestampToTime = (timestamp: number, includeDayOfWeek: boolean = false) => {
  const date = new Date(timestamp * 1000);
  const hours = date.getHours();
  const timeOfDay = hours > 11 ? 'PM' : 'AM';
  if (includeDayOfWeek) {
    const currentDate = new Date();
    const dayIndex = date.getDay();
    const dayOfWeek = days[dayIndex];
    if (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    ) {
      return `Today`;
    } else {
      return `${dayOfWeek}`;
    }
  } else {
    return `${hours % 12 === 0 ? 12 : hours % 12}${timeOfDay}`;
  }
};
