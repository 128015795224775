const Clock = () => (
  <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.721 16.2C13.6974 16.2 16.921 12.9765 16.921 9.00005C16.921 5.0236 13.6974 1.80005 9.721 1.80005C5.74455 1.80005 2.521 5.0236 2.521 9.00005C2.521 12.9765 5.74455 16.2 9.721 16.2ZM10.171 5.40005C10.171 5.15152 9.96952 4.95005 9.721 4.95005C9.47247 4.95005 9.271 5.15152 9.271 5.40005V9.00005C9.271 9.1194 9.31841 9.23386 9.4028 9.31825L11.9484 11.8638C12.1241 12.0396 12.409 12.0396 12.5848 11.8638C12.7605 11.6881 12.7605 11.4032 12.5848 11.2274L10.171 8.81365V5.40005Z"
      fill="#EFF7FF"
    />
  </svg>
);

export default Clock;
