import React from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import HeaderNavigation from '../navigation/HeaderNavigation';
import styled from 'styled-components';
import { mediaLargerThan } from '../../../common/styles';

export type HeaderProps = {
  isCloseNav: boolean;
  setIsCloseNav: React.Dispatch<React.SetStateAction<boolean>>;
  runScroll: () => void;
};

const Header: React.FC<HeaderProps> = ({ isCloseNav, setIsCloseNav, runScroll }) => {
  return (
    <header className={styles.header}>
      <div>
        <Link
          to="/"
          onClick={() => {
            runScroll();
            setIsCloseNav(true);
          }}
        >
          <MainTitle>WHENZY</MainTitle>
        </Link>
        <HeaderSubtitle>OF MOORESTOWN</HeaderSubtitle>
      </div>
      <HeaderNavigation isCloseNav={isCloseNav} setIsCloseNav={setIsCloseNav} />
      <script src="https://cdn.usefathom.com/script.js" data-site="SWQCCCWT" defer></script>
    </header>
  );
};

const MainTitle = styled.h1`
  font-size: 22px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 4px;
  -webkit-text-fill-color: var(--light-color);
  -webkit-text-stroke-color: var(--light-color);
  -webkit-text-stroke-width: 1.2px;

  ${mediaLargerThan.mobile} {
    font-size: 28px;
  }

  ${mediaLargerThan.tablet} {
    font-size: 32px;
  }
`;

const HeaderSubtitle = styled.h2`
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1.5px;
  cursor: default;
  font-weight: 400;

  ${mediaLargerThan.mobile} {
    font-size: 14px;
  }

  ${mediaLargerThan.tablet} {
    font-size: 16px;
  }
`;

export default Header;
