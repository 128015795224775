import React, { useState } from 'react';
import styles from './styles.module.css';
// import plane from '../../../assets/images/plane.svg'; TODO when realise chat support
import search from '../../../assets/images/search.svg';
import close from '../../../assets/images/close.svg';
import { useWindowSize } from './useWindowSize';

export type SearchInputProps = {
  onSearch: (message: string) => void;
};

const SearchInput: React.FC<SearchInputProps> = ({ onSearch }) => {
  const placeholder: string = 'Search by event, group or keyword';
  const [value, setValue] = useState<string>('');
  const { width } = useWindowSize();

  const handleClear = () => {
    setValue('');
    onSearch('');
  };

  return (
    <div className={styles.search}>
      <img src={search} className={styles.searchIcon} />
      <textarea
        placeholder={placeholder}
        className={styles.input}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onSearch(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        rows={width < 912 ? 1 : 2}
      />

      <img
        src={close}
        className={styles.clear}
        onClick={() => {
          handleClear();
        }}
      />

      {/* TODO when realise chat support */}
      {/* <img
        className={`${styles.searchIcon} ${styles.searchButton}`}
        src={plane}
        onClick={searchHandler}
      /> */}
    </div>
  );
};

export default SearchInput;
