const Location = () => (
  <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.77199 3.64528C7.23229 1.18497 11.2212 1.18497 13.6815 3.64528C16.1418 6.10558 16.1418 10.0945 13.6815 12.5548L9.22676 17.0096L4.77199 12.5548C2.31168 10.0945 2.31168 6.10558 4.77199 3.64528ZM9.22676 9.90005C10.2209 9.90005 11.0268 9.09416 11.0268 8.10005C11.0268 7.10594 10.2209 6.30005 9.22676 6.30005C8.23265 6.30005 7.42676 7.10594 7.42676 8.10005C7.42676 9.09416 8.23265 9.90005 9.22676 9.90005Z"
      fill="#EFF7FF"
    />
  </svg>
);

export default Location;
