import React, { useState, useEffect, useRef } from 'react';
import Calendar from '../../components/Calendar';
import Chat from '../../components/Chat';
import styles from './styles.module.css';
import DateWeatherPanel from '../../components/DateWeatherPanel';
import { getCurrentWeather } from '../../utils/store/store';
import { useStore } from '../../utils/store/useStore';
import { WeatherInfo } from '../../utils/api/getData';
import { useScrollVisibility } from '../../utils/funcs/useScrollVisibility';
import styled from 'styled-components';
import { mediaLargerThan } from '../../common/styles';

export type CalendarPageProps = {
  isRunTopScroll?: boolean;
};

const CalendarPage: React.FC<CalendarPageProps> = ({ isRunTopScroll }) => {
  const [weatherData, setWeatherData] = useState<WeatherInfo | null>(null);
  const { dispatch } = useStore();
  const calendarRef = useRef<HTMLDivElement>(null);
  const [isCalendarVisible, setCalendarVisible] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    getCurrentWeather(dispatch)
      .then((data) => {
        setWeatherData(data);
      })
      .catch((error) => {
        console.error('Error fetching weather data:', error);
      });
  }, [dispatch]);

  useScrollVisibility((isVisible) => {
    setCalendarVisible(isVisible);
  });

  return (
    <div className={styles.root}>
      <div className={styles.mobileWrapper}>
        <DateWeatherPanel
          weather={weatherData}
          isHeaderVisible={isCalendarVisible && scrollTop <= 5}
        />
        <CalendarWrapper ref={calendarRef}>
          <Calendar isRunTopScroll={isRunTopScroll} onScroll={setScrollTop} />
          <Chat />
        </CalendarWrapper>
      </div>
    </div>
  );
};

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  overflow: hidden;
  margin-bottom: 75px;
  gap: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 0;
  }
`;

export default CalendarPage;
