import React from 'react';
import { DateTime } from 'luxon';
import styles from './styles.module.css';
import styled from 'styled-components';
import { mediaLargerThan } from '../../../common/styles';

export type CalendarDateProps = {
  date: string;
  isFirst?: boolean;
  isCurrentDay?: boolean;
};

export type DateView = {
  eventDate: DateTime;
};

const DateInfo = ({ eventDate }: DateView) => {
  const isEventYearGreaterThanCurrentYear = eventDate.year > DateTime.now().year;

  if (isEventYearGreaterThanCurrentYear) {
    return (
      <>
        <DateInfoMonth>
          {eventDate.monthShort?.toUpperCase()} {eventDate.day}
        </DateInfoMonth>
        <DateInfoDayShort>{eventDate.weekdayShort?.toUpperCase()}</DateInfoDayShort>
        <DateInfoYear>{eventDate.year}</DateInfoYear>
      </>
    );
  }

  return (
    <>
      <DateInfoMonth>{eventDate.monthShort?.toUpperCase()}</DateInfoMonth>
      <DateInfoDayNum>{eventDate.day}</DateInfoDayNum>
      <DateInfoDayShort>{eventDate.weekdayShort?.toUpperCase()}</DateInfoDayShort>
    </>
  );
};

export const CalendarDate: React.FC<CalendarDateProps> = ({ date, isFirst, isCurrentDay }) => {
  const eventDate = DateTime.fromFormat(date, 'yyyy-LL-dd');

  return (
    <div className={styles.eventDateWrapper}>
      <EventDateContainer>{!isCurrentDay && <DateInfo eventDate={eventDate} />}</EventDateContainer>
      {!isCurrentDay && <div className={`${styles.point} ${isFirst ? styles.first : ''}`} />}
    </div>
  );
};

const BaseDateInfoLine = styled.span`
  display: block;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
`;

const DateInfoMonth = styled(BaseDateInfoLine)`
  font-size: 0.75rem;
  line-height: 1rem;

  ${mediaLargerThan.mobile} {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  ${mediaLargerThan.tablet} {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const DateInfoDayNum = styled(BaseDateInfoLine)`
  font-size: 1.5rem;

  ${mediaLargerThan.mobile} {
    font-size: 1.75rem;
  }

  ${mediaLargerThan.tablet} {
    font-size: 2rem;
  }
`;

const EventDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  position: absolute;
  top: 0.75rem;
  left: -0.5rem;

  ${mediaLargerThan.tablet} {
    width: 100px;
    top: 0.5rem;
    left: -2.5rem;
  }
`;

const DateInfoDayShort = styled(DateInfoMonth)``;
const DateInfoYear = styled(DateInfoMonth)``;
